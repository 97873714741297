<template>
  <div class="container-fluid full-width-page-wrapper steps-container-wrapper p-0">
    <h2 class="page-title">Edit Report</h2>
    <ifac-submit-report-modal/>
    <ifac-report-stepper
      :report="report"
      :budget-id="budgetId"
      :report-loading="busy"
      :contract-id="contractId"
      :has-errors="hasErrors(report)"
      :organization-list-id="organizationListId"
      :last-section="lastSection(report, $route)"
      :uncompleted-sections="uncompletedSections(report)"
      @update="preparePayload"
      @submit-report="submitReport"
      @save-exit="saveAndExit"
      @save-continue="updateReport"
      @change-budget="changeFile('budgetId', $event)"
      @change-contract="changeFile('contractId', $event)"
      @change-organization-list="changeFile('organizationListId', $event)"
    />
  </div>
</template>

<script>
import { IfacReportStepper, IfacSubmitReportModal } from '@ifac/ui';
import { mapState, mapGetters, mapMutations } from 'vuex';
import S3Uploader from '@/services/S3Uploader';
import Reports from '@/services/Api/Reports';

export default {
  name: 'ConferenceReport',
  components: {
    IfacSubmitReportModal,
    IfacReportStepper,
  },
  created() {
    this.fetchReport();
  },
  computed: {
    ...mapState({
      metas: (state) => state.report.metas,
    }),
    ...mapGetters('report', {
      uncompletedSections: 'uncompletedSections',
      lastSection: 'lastSection',
      hasErrors: 'hasErrors',
    }),
    conferenceId() {
      return this.$route.params.id;
    },
    reportId() {
      return this.$route.params.reportId;
    },
  },
  data() {
    return {
      report: null,
      section: null,
      payload: null,
      budgetId: null,
      contractId: null,
      organizationListId: null,
    };
  },
  methods: {
    ...mapMutations({
      setRequestError: 'formHelpers/setError',
      setUploadStatus: 'uiHelpers/setReportFileUpload',
    }),
    filePayload($e) {
      return {
        id: this.reportId,
        fileName: $e.file.name,
      };
    },
    preparePayload(data) {
      this.section = data.section;
      this.payload = data.payload;
    },
    updatePayload() {
      return {
        ...this.payload,
        ...{ id: this.reportId },
      };
    },
    submitPayload() {
      return {
        ...this.payload,
        ...this.report,
      };
    },
    changeFile(field, $e) {
      this.setUploadStatus(true);
      Reports.uploadFile(this.filePayload($e))
        .then((response) => {
          const { id: uploadId, url } = response.data.data;
          S3Uploader.put(url, $e.file)
            .then(() => {
              this.$data[field] = uploadId;
            }).catch(() => {
              this.$snack.error({
                text: 'Something went wrong while uploading the file, please try again.',
              });
            });
        }).catch(() => {
          this.$snack.error({
            text: 'Something went wrong while uploading the file, please try again.',
          });
        }).finally(() => {
          this.setUploadStatus(false);
        });
    },
    async fetchReport() {
      this.busy = true;
      try {
        const response = await Reports.get(this.reportId);
        const report = response?.data?.data;

        if (response?.status === 200 && report) {
          this.report = report;
        }
      } catch (error) {
        console.log(error);
      }
      this.busy = false;
    },
    async updateReport() {
      try {
        const response = await Reports.update(this.updatePayload());
        const report = response?.data?.data;

        if (response?.status === 200 && report) {
          this.report = report;
          this.setRequestError(null);
        }
      } catch (error) {
        if (error.response?.status === 422) {
          this.$snack.success({
            text: error.response.data.error.message,
          });
          this.setRequestError(error.response.data.error);
        } else {
          this.$snack.success({
            text: 'There was an error updating the section, please try again.',
          });
        }
      }
    },
    saveAndExit() {
      this.updateReport();
      this.$router.push({
        name: 'ConferencesOverview',
        params: {
          id: this.conferenceId,
        },
      });
    },
    async submitReport() {
      try {
        await this.updateReport();
        await Reports.submit(this.submitPayload());
        this.$bvModal.show('submit-report');
      } catch (error) {
        if (error.response?.status === 422) {
          this.errors = error.response.data.error?.details;
        }
      }
    },
  },
};
</script>
